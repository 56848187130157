/* global f47 */

import LazyLoad from './vendor/lazyload';

NodeList.prototype.forEach = Array.prototype.forEach;

if ( ! Element.prototype.matches ) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if ( ! Element.prototype.closest ) {
	Element.prototype.closest = function ( s ) {
		let el = this;

		do {
			if ( Element.prototype.matches.call( el, s ) ) return el;
			el = el.parentElement || el.parentNode;
		} while ( el !== null && el.nodeType === 1 );
		return null;
	};
}

const menu = document.querySelector( '.elementor-widget-primary-menu nav' ),
	menuBody = document.getElementById( 'menu-main-menu' ),
	hamburger = document.querySelector( '.hamburger' ),
	subMenuDepth1 = Array.from(
		document.querySelectorAll(
			'.elementor-widget-primary-menu .sub-menu-depth-1'
		)
	),
	subMenuDepth2 = Array.from(
		document.querySelectorAll(
			'.elementor-widget-primary-menu .sub-menu-depth-2'
		)
	),
	menuItems1Level = document.querySelectorAll(
		'.elementor-widget-primary-menu .sub-menu-depth-1 > .menu-item'
	),
	menuTopItems = document.querySelectorAll(
		'.elementor-widget-primary-menu .menu > .menu-item-has-children > .menu-item-link'
	),
	menuItems1LevelWithChildren = document.querySelectorAll(
		'.elementor-widget-primary-menu .menu > .menu-item-has-children'
	),
	menuItems2LevelWithChildren = document.querySelectorAll(
		'.elementor-widget-primary-menu .sub-menu-depth-1 > .menu-item-has-children'
	),
	body = document.querySelector( 'body' ),
	headerHeight = 90,
	tabletWidth = '1200px',
	lazyLoad = new LazyLoad();

let prevScroll = 0,
	scroll = window.scrollY,
	isHeaderHidden = false;

window.addEventListener('load', () => {
	lazyLoad.update();
});

const Menu =
	window.Menu ||
	( function ( document ) {
		const app = {
			init() {
				app.enableSticky();
				app.bindEvents();
				app.bindMenuEvents();
			},
			bindEvents() {
				document.defaultView.window.addEventListener(
					'resize',
					app.bindMenuEvents
				);
			},
			bindMenuEvents() {
				if ( app.isMobile() ) {
					app.disableDesktop();
					app.enableMobile();

					return;
				}

				app.disableMobile();
				app.enableDesktop();
			},
			isMobile() {
				return window.matchMedia( '(max-width: ' + tabletWidth + ')' )
					.matches;
			},
			enableSticky() {
				document.defaultView.window.addEventListener(
					'scroll',
					app.enableStickyCallback
				);
			},
			enableStickyCallback() {
				prevScroll = scroll;
				scroll = window.scrollY;

				if ( app.isNeedToHide() ) {
					body.classList.add( 'header-hidden' );
					isHeaderHidden = true;
					Scroll.enable();

					return;
				}

				if ( prevScroll > scroll && isHeaderHidden ) {
					body.classList.remove( 'header-hidden' );
					isHeaderHidden = false;
				}
			},
			isNeedToHide() {
				return (
					scroll > headerHeight &&
					prevScroll < scroll &&
					! isHeaderHidden
				);
			},
			enableDesktop() {
				menuItems1Level.forEach( function ( el ) {
					el.addEventListener(
						'mouseover',
						app.desktopFirstDepthHoverCallback
					);
					el.addEventListener(
						'mouseout',
						app.desktopRemoveHoverCallback
					);
				} );
				body.addEventListener( 'click', app.hideMenu );
				menuTopItems.forEach( function ( el ) {
					el.addEventListener(
						'click',
						app.desktopTopItemsToggleCallback
					);
					el.addEventListener(
						'mouseover',
						app.desktopTopItemsHoverCallback
					);
					el.addEventListener(
						'mouseout',
						app.desktopRemoveHoverCallback
					);
				} );
			},
			disableDesktop() {
				menuItems1Level.forEach( function ( el ) {
					el.removeEventListener(
						'mouseover',
						app.desktopFirstDepthHoverCallback
					);
					el.removeEventListener(
						'mouseout',
						app.desktopRemoveHoverCallback
					);
				} );
				body.removeEventListener( 'click', app.hideMenu );
				body.removeEventListener( 'mouseover', app.hideMenu );
				menuTopItems.forEach( function ( el ) {
					el.removeEventListener(
						'click',
						app.desktopTopItemsToggleCallback
					);
					el.removeEventListener(
						'mouseover',
						app.desktopTopItemsHoverCallback
					);
					el.removeEventListener(
						'mouseout',
						app.desktopRemoveHoverCallback
					);
				} );
			},
			enableMobile() {
				hamburger.addEventListener( 'click', app.burgerCallback );
				document.body.addEventListener('click', app.closeMobileMenuCallback );
				menuItems1LevelWithChildren.forEach( function ( el ) {
					el.addEventListener(
						'click',
						app.toggleSubMenuFirstLevelCallback
					);
				} );
				menuItems2LevelWithChildren.forEach( function ( el ) {
					el.addEventListener(
						'click',
						app.toggleSubMenuCallback
					);
				} );
			},
			disableMobile() {
				document.body.addEventListener('click', app.closeMobileMenuCallback );
				menuItems1LevelWithChildren.forEach( function ( el ) {
					el.removeEventListener(
						'click',
						app.toggleSubMenuFirstLevelCallback
					);
				} );
				menuItems2LevelWithChildren.forEach( function ( el ) {
					el.removeEventListener(
						'click',
						app.toggleSubMenuCallback
					);
				} );
			},
			desktopFirstDepthHoverCallback() {
				if ( this.classList.contains( 'menu-item-active' ) ) {
					return;
				}

				const hoveredElement = this;

				hoveredElement.classList.add( 'menu-item-hover' );

				setTimeout( function () {
					if (
						! hoveredElement.classList.contains( 'menu-item-hover' )
					) {
						return;
					}

					const activeMenuItems = hoveredElement.parentElement.querySelectorAll(
						'.menu-item-active'
					);

					[].forEach.call( activeMenuItems, function ( el ) {
						el.classList.remove( 'menu-item-active' );
					} );

					hoveredElement.classList.add( 'menu-item-active' );
				}, 300 );
			},
			desktopRemoveHoverCallback() {
				this.classList.remove( 'menu-item-hover' );
			},
			desktopTopItemsToggleCallback( e ) {
				e.preventDefault();
				const parent = e.target.parentElement;

				if (
					parent.classList.contains(
						'menu-item-children-show-hovered'
					)
				) {
					return;
				}

				parent.classList.toggle( 'menu-item-children-show' );
			},
			desktopTopItemsHoverCallback() {
				const hoveredElement = this;
				hoveredElement.classList.add( 'menu-item-hover' );

				setTimeout( function () {
					if (
						! hoveredElement.classList.contains( 'menu-item-hover' )
					) {
						return;
					}

					app.toggleDropdown( hoveredElement );
				}, 300 );
			},
			toggleDropdown( link ) {
				const parent = link.parentElement,
					subMenu = parent.querySelector( '.sub-menu-depth-1' );

				[].forEach.call( menuItems1LevelWithChildren, function ( el ) {
					if ( el === parent ) {
						return;
					}

					el.classList.remove( 'menu-item-children-show' );
					el.querySelector( '.sub-menu-depth-1' ).removeAttribute(
						'style'
					);
				} );

				subMenu.style.display = parent.classList.contains( 'menu-item-max-level-3' ) ? 'flex' : 'display';

				parent.classList.add( 'menu-item-children-show' );
				parent.classList.add( 'menu-item-children-show-hovered' );

				setTimeout( function () {
					if (
						! parent.classList.contains( 'menu-item-children-show' )
					) {
						return;
					}

					parent.classList.remove(
						'menu-item-children-show-hovered'
					);
				}, 500 );

				lazyLoad.update();
			},
			hideMenu( e ) {
				if ( app.hasMenuInParent( e.target ) ) {
					return;
				}

				const openedDropDownMenu = document.querySelectorAll(
					'.menu-item-children-show'
				);

				if ( ! openedDropDownMenu.length ) {
					return;
				}

				[].forEach.call( openedDropDownMenu, function ( el ) {
					el.classList.remove( 'menu-item-children-show' );
					el.querySelector( '.sub-menu-depth-1' ).removeAttribute(
						'style'
					);
				} );
			},
			hasMenuInParent( el ) {
				while ( el.parentNode ) {
					if ( el === menu ) {
						return true;
					}
					el = el.parentNode;
				}

				return false;
			},
			burgerCallback() {
				if ( hamburger.classList.contains( 'is-active' ) ) {
					menu.classList.remove( 'is-active' );
					hamburger.classList.remove( 'is-active' );
					Scroll.enable();
					return;
				}

				hamburger.classList.add( 'is-active' );
				menu.classList.add( 'is-active' );
				Scroll.disable();
			},
			closeMobileMenuCallback( e ) {
				if ( ! menu.classList.contains( 'is-active' ) ) {
					return;
				}
				let el = e.target;
				while ( el.parentNode ) {
					if ( el === menu || el === hamburger ) {
						return;
					}
					el = el.parentNode;
				}
				app.burgerCallback();
			},
			toggleSubMenuFirstLevelCallback( e ) {
				const hasDepth1 = e
					.composedPath()
					.filter( ( x ) => subMenuDepth1.includes( x ) );
				if ( hasDepth1.length ) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				this.classList.toggle( 'is-active' );
				menu.classList.toggle( 'is-sub-menu-is-active' );
			},
			toggleSubMenuCallback( e ) {
				const hasDepth2 = e
					.composedPath()
					.filter( ( x ) => subMenuDepth2.includes( x ) );
				if ( hasDepth2.length ) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				this.classList.toggle( 'is-active' );
			},
		};
		return app;
	} )( document );
Menu.init();

const Scroll =
	window.Scroll ||
	( function ( document ) {
		const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
		let wheelOpt;
		let wheelEvent;

		const app = {
			init() {
				let supportsPassive = false;
				try {
					document.defaultView.window.addEventListener(
						'test',
						null,
						Object.defineProperty( {}, 'passive', {
							get() {
								supportsPassive = true;
							},
						} )
					);
				} catch ( e ) {}

				wheelOpt = supportsPassive ? { passive: false } : false;
				wheelEvent =
					'onwheel' in document.createElement( 'div' )
						? 'wheel'
						: 'mousewheel';
			},
			disable() {
				document.defaultView.window.addEventListener(
					'DOMMouseScroll',
					app.preventDefault,
					false
				);
				document.defaultView.window.addEventListener(
					wheelEvent,
					app.preventDefault,
					wheelOpt
				);
				document.defaultView.window.addEventListener(
					'touchmove',
					app.preventDefault,
					wheelOpt
				);
				document.defaultView.window.addEventListener(
					'keydown',
					app.preventDefaultForScrollKeys,
					false
				);
			},
			enable() {
				document.defaultView.window.removeEventListener(
					'DOMMouseScroll',
					app.preventDefault,
					false
				);
				document.defaultView.window.removeEventListener(
					wheelEvent,
					app.preventDefault,
					wheelOpt
				);
				document.defaultView.window.removeEventListener(
					'touchmove',
					app.preventDefault,
					wheelOpt
				);
				document.defaultView.window.removeEventListener(
					'keydown',
					app.preventDefaultForScrollKeys,
					false
				);
			},
			isMenuHasScroll() {
				return menuBody.offsetHeight - window.innerHeight + 80 > 0;
			},
			scrollToTopAtTopPosition( e ) {
				return menu.scrollTop === 0 && e.deltaY < 0
			},
			scrollToBottomAtBottomPosition( e ) {
				return menu.scrollTop + menu.offsetHeight === menuBody.offsetHeight && e.deltaY > 0
			},
			isScrollInMenu( element ) {
				while ( element ) {
					if ( element.classList && element.classList.contains( 'menu' ) ) {
						return true;
					}
					element = element.parentNode;
				}

				return false;
			},
			preventDefault( e ) {
				if (
					! app.isMenuHasScroll()
					|| app.scrollToTopAtTopPosition( e )
					|| app.scrollToBottomAtBottomPosition( e )
					|| ! app.isScrollInMenu( e.target )
				) {
					e.preventDefault();
				}
			},
			preventDefaultForScrollKeys( e ) {
				if ( keys[ e.keyCode ] ) {
					app.preventDefault( e );
					return false;
				}
			},
		};
		return app;
	} )( document );
Scroll.init();

const Pagination =
	window.Pagination ||
	( function ( document ) {
		const app = {
			init() {
				app.bindPagination();
			},
			bindPagination() {
				const grid = document.querySelectorAll( '.posts-grid-wrapper' );

				if ( ! grid.length ) {
					return;
				}

				grid.forEach( function ( el ) {
					el.addEventListener( 'click', app.paginationCallback );
				} );
			},
			paginationCallback( e ) {
				const path = e.composedPath();
				if (
					'A' === e.target.tagName &&
					e.target.classList.contains( 'page-numbers' )
				) {
					e.preventDefault();
					app.sendAjax( path[ 0 ] );
				}

				if (
					path[ 1 ] &&
					'A' === path[ 1 ].tagName &&
					path[ 1 ].classList.contains( 'page-numbers' )
				) {
					e.preventDefault();
					app.sendAjax( path[ 1 ] );
				}
			},
			sendAjax( el ) {
				const href = el.getAttribute( 'href' ),
					wrapper = el.closest( '.posts-grid-wrapper' ),
					pageMatch = href.match( /.+\/(\d+)/ ),
					paged = pageMatch ? pageMatch[ 1 ] : 1,
					xhttp = new XMLHttpRequest(),
					queryVarsName = wrapper.nextElementSibling.value,
					url = new URL( f47.adminUrl );

				url.search = new URLSearchParams( {
					action: 'pagination',
					nonce: f47.paginateNonce,
					query_vars: JSON.stringify( f47[ queryVarsName ] ),
					paged,
				} );

				xhttp.onreadystatechange = function () {
					if ( 4 !== this.readyState || 200 !== this.status ) {
						return;
					}
					const response = JSON.parse( xhttp.responseText );

					if ( ! response.data ) {
						return;
					}

					wrapper.innerHTML = response.data;
					wrapper.scrollIntoView( {
						behavior: 'smooth',
						block: 'start',
					} );

					if ( f47.isArchive ) {
						window.history.pushState( {}, null, href );
					}
				};

				xhttp.open( 'GET', url.toString(), true );
				xhttp.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded;'
				);
				xhttp.send();
			},
		};
		return app;
	} )( document );
Pagination.init();

const TableOfContent =
	window.TableOfContent ||
	( function ( document ) {
		const app = {
			init() {
				const widgets = document.querySelectorAll(
					'.widget_lpwtoc_widget'
				);
				if ( ! widgets.length ) {
					return;
				}

				widgets.forEach( function ( el ) {
					el.querySelectorAll( '.lwptoc_item a' ).forEach( function (
						link
					) {
						link.addEventListener( 'click', app.smoothScroll );
					} );
				} );
			},
			smoothScroll( e ) {
				e.preventDefault();

				const anchor = document.querySelector(
					this.getAttribute( 'href' )
				);
				window.scrollTo( {
					top: anchor.offsetTop,
					behavior: 'smooth',
				} );
			},
		};
		return app;
	} )( document );
TableOfContent.init();

const WPMLToggle =
	window.WPMLToggle ||
	( function ( document ) {
		const app = {
			init() {
				const currentLanguageElements = document.querySelectorAll(
					'.wpml-ls-current-language > .menu-item-link'
				);

				if ( ! currentLanguageElements.length ) {
					return;
				}

				currentLanguageElements.forEach( function ( el ) {
					el.addEventListener( 'click', app.toggleSubMenu );
				} );
			},
			toggleSubMenu( e ) {
				e.preventDefault();
				this.nextElementSibling.classList.toggle( 'sub-menu-opened' );
			},
		};
		return app;
	} )( document );
WPMLToggle.init();

const Sidebar =
	window.Sidebar ||
	( function ( document ) {
		const sidebar = document.querySelector( '.sidebar' ),
			wrapper = sidebar ? sidebar.parentElement : null;

		const app = {
			init() {
				if ( ! sidebar ) {
					return;
				}

				if ( window.outerWidth <= 1200 ) {
					return;
				}

				app.resize();
				document.defaultView.window.addEventListener( 'scroll', () => {
					app.scroll();
				} );
				document.defaultView.window.addEventListener( 'resize', () => {
					app.resize();
				} );
			},

			resize() {
				const { paddingLeft, paddingRight } = window.getComputedStyle(
						wrapper
					),
					parentWidth =
						wrapper.offsetWidth -
						parseInt( paddingLeft ) -
						parseInt( paddingRight );

				sidebar.style.width = `${ parentWidth }px`;
				wrapper.style.position = 'relative';
			},

			scroll() {
				const sidebarScroll = Math.max(
						document.documentElement.scrollTop,
						document.body.scrollTop
					),
					topOffset = document.querySelector( '.sidebar' ).parentNode.getBoundingClientRect().top + document.documentElement.scrollTop,
					sidebarHeight = sidebar.parentNode.offsetHeight,
					bottomOffset = topOffset + sidebarHeight,
					stickBottom = bottomOffset - sidebar.offsetHeight;

				if ( app.isNeedToRemoveSticky( sidebarScroll, topOffset ) ) {
					sidebar.classList.remove( 'sticky' );

					return;
				}

				if ( app.isBottom( sidebarScroll, stickBottom ) ) {
					sidebar.classList.add( 'bottom' );
				} else if ( sidebarScroll <= stickBottom ) {
					sidebar.classList.remove( 'bottom' );
				}

				if ( app.isStick( sidebarScroll, topOffset, bottomOffset ) ) {
					sidebar.classList.add( 'sticky' );
				}
			},

			isNeedToRemoveSticky( sidebarScroll, topOffset ) {
				return (
					! sidebar.classList.contains( 'stick' ) &&
					sidebarScroll <= topOffset
				);
			},

			isBottom( sidebarScroll, stickBottom ) {
				return (
					! sidebar.classList.contains( 'bottom' ) &&
					sidebarScroll > stickBottom
				);
			},
			isStick( sidebarScroll, topOffset, bottomOffset ) {
				return (
					sidebarScroll >= topOffset && sidebarScroll <= bottomOffset
				);
			},
		};
		return app;
	} )( document );
Sidebar.init();

const ScrollToTop =
	window.ScrollToTop ||
	( function ( document ) {
		const scrollToTop = document.querySelector( '.scroll-to-top' );

		const app = {
			init() {
				scrollToTop.addEventListener( 'click', app.scrollToTop );
				document.defaultView.window.addEventListener(
					'scroll',
					app.changeVisiblity
				);
			},

			scrollToTop( e ) {
				e.preventDefault();
				window.scrollTo( { top: 0, behavior: 'smooth' } );
			},

			changeVisiblity() {
				scrollToTop.style.opacity = window.scrollY > 100 ? 1 : 0;
			},
		};
		return app;
	} )( document );
ScrollToTop.init();

const CopyLink =
	window.CopyLink ||
	( function ( document ) {
		const app = {
			init() {
				const copyLinks = document.querySelectorAll( '.btn--copy' );

				copyLinks.forEach( function ( el ) {
					el.addEventListener( 'click', app.copyLink );
				} );
			},

			copyLink( e ) {
				e.preventDefault();
				const el = this;
				el.classList.add('copied');
				navigator.clipboard.writeText( el.getAttribute( 'href' ) );
				setTimeout( function () {
					el.classList.remove('copied');
				}, 700 );
			},
		};
		return app;
	} )( document );
CopyLink.init();
